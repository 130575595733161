:root {
  --color-primary: #e0deef;
  --color-font-primary: #000000;
  --color-primary-dark: #7d79a8;
  --color-font-primary-dark: #ffffff;
  --color-body: #6f6d88;
  --color-font-body: #ffffff;
  --color-body-accent: #5b5675;
  --color-font-body-accent: #ffffff;
  --color-accent: #000000;
  --color-font-accent: #ffffff;
  --color-highlight: #ededed;
  --color-font-highlight: #000000;

  --color-shadow: #00000088;

  --border-radius: 20px 0px 20px 0px;
  --box-shadow: 0 0 10px 4px var(--color-shadow);
  --margins: 10px;
  --paddings: 10px;
  --card-width: min(1000px, 90%);
}

* {
  transition: color 0.5s, background-color 0.5s;
}

body {
  margin: 0;
  font-size: 18pt;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  background-color: var(--color-body);
  display: grid;
  width: 100vw;
  height: 100vh;
  grid-template:
    "header header" min-content
    "nav body" auto
    "footer footer" min-content / min-content auto;
  font-family: "Roboto";
}

.header {
  grid-area: header;
  background-color: var(--color-primary);
  color: var(--color-font-primary);
  margin: var(--margins);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: row;
}

.header > .title {
  font-size: 1.5em;
  padding: var(--paddings);
  height: 100%;
  text-decoration: underline;
  text-shadow: 2px 2px 5px var(--color-shadow);
  font-weight: 700;
}

.header > .logo {
  margin: 5px;
  object-fit: contain;
}

.footer {
  grid-area: footer;
  background-color: var(--color-primary-dark);
  color: var(--color-font-primary-dark);
  box-shadow: var(--box-shadow);
  margin: var(--margins);
  border-radius: var(--border-radius);
  padding: var(--paddings);
}

a {
  padding-left: 10px;
  padding-right: 10px;
  border-radius: var(--border-radius);
  text-decoration: none;
  border-bottom: solid 2px;
  color: inherit;
}

a:hover {
  background-color: var(--color-body);
  color: var(--color-font-body);
}

.nav {
  margin: var(--margins);
  grid-area: nav;
  width: fit-content;
}

.nav > a {
  padding: var(--paddings);
  background-color: var(--color-primary-dark);
  color: var(--color-font-primary-dark);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  text-decoration: none;
  margin-bottom: 10px;
  display: block;
  text-align: center;
  border: initial;
}

.nav > a:hover {
  background-color: var(--color-body);
  color: var(--color-font-body);
}

.body {
  grid-area: body;
  overflow-y: auto;
}

.card {
  border-radius: var(--border-radius);
  margin: var(--margins);
  padding: 0px;
  background-color: var(--color-primary-dark);
  color: var(--color-font-primary-dark);
  width: var(--card-width);
  margin-left: auto;
  margin-right: auto;
  box-shadow: var(--box-shadow);
}

.card .title {
  background-color: var(--color-primary);
  color: var(--color-font-primary);
  padding: var(--paddings);
  border-radius: var(--border-radius);
  margin: 0px;
}

.card .content {
  color: var(--color-font);
  padding: var(--paddings);
}

.grid {
  display: grid;
}

.grid.g-1 {
  grid-template-columns: repeat(1, 1fr);
}

.grid.g-2 {
  grid-template-columns: repeat(2, 1fr);
}

.grid.g-3 {
  grid-template-columns: repeat(3, 1fr);
}

.grid.g-4 {
  grid-template-columns: repeat(4, 1fr);
}

.grid.g-5 {
  grid-template-columns: repeat(5, 1fr);
}

.grid.g-6 {
  grid-template-columns: repeat(6, 1fr);
}

.grid.g-7 {
  grid-template-columns: repeat(7, 1fr);
}

.grid.g-8 {
  grid-template-columns: repeat(8, 1fr);
}

.grid.g-9 {
  grid-template-columns: repeat(9, 1fr);
}

.grid.g-10 {
  grid-template-columns: repeat(10, 1fr);
}

.grid.g-11 {
  grid-template-columns: repeat(11, 1fr);
}

.grid.g-12 {
  grid-template-columns: repeat(12, 1fr);
}

.item {
  grid-row: auto / span 1;
  margin: var(--margins);
  padding: var(--paddings);
}

.item.s-1 {
  grid-column: auto / span 1;
}

.item.s-2 {
  grid-column: auto / span 2;
}

.item.s-3 {
  grid-column: auto / span 3;
}

.item.s-4 {
  grid-column: auto / span 4;
}

.item.s-5 {
  grid-column: auto / span 5;
}

.item.s-6 {
  grid-column: auto / span 6;
}

.item.s-7 {
  grid-column: auto / span 7;
}

.item.s-8 {
  grid-column: auto / span 8;
}

.item.s-9 {
  grid-column: auto / span 9;
}

.item.s-10 {
  grid-column: auto / span 10;
}

.item.s-11 {
  grid-column: auto / span 11;
}

.item.s-12 {
  grid-column: auto / span 12;
}

input.label {
  font-size: 1.25em;
  border-bottom: solid 2px;
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: row;
  flex-wrap: nowrap;
}